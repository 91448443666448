import React from "react";
import { useSelector } from "react-redux";

const Support = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <>
      <div className="container">
        <h1 className=" text-black text-[3em] font-medium tracking-tight leading-none mb-1 ">
          Customer Support
        </h1>
        <hr className="mb-3" />
        <div className="flex justify-between customersupport">
          <div>
            {userInfo.name ? (
              <h1 className="font-serif text-3xl">Hello, {userInfo.name}!</h1>
            ) : (
              <h1 className="font-serif text-3xl">Hello, There!</h1>
            )}
            <p className="text-2xl font-light">How can we help?</p>
            {/*  */}
            <p className="mt-5 text-2xl font-medium text-black">
              Other Methods of Contact:
            </p>
            <a href="tel: 3213169898">
              <p className="font-light text-1xl">
                Office Number: (321) 316-9898
              </p>
            </a>
            <a href="mailto: anaidmdiazplaza@gmail.com">
              <p className="font-light text-1xl">
                Email: anaidmdiazplaza@gmail.com
              </p>
            </a>
          </div>

          <form
            className="w-full max-w-lg mt-4"
            action="https://formsubmit.co/anaidmdiazplaza@gmail.com"
            method="POST"
          >
            <div className="flex flex-wrap mb-6 -mx-3">
              <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  for="grid-first-name"
                >
                  First Name
                </label>
                <input
                  className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-red-500 rounded appearance-none focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Jane"
                  name="first-name"
                />
              </div>
              <div className="w-full px-3 md:w-1/2">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  for="grid-last-name"
                >
                  Last Name
                </label>
                <input
                  className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Doe"
                  name="last-name"
                />
              </div>
            </div>

            <div className="flex flex-wrap mb-6 -mx-3">
              <div className="w-full px-3">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  for="grid-password"
                >
                  Category You Wish To Inquire About
                </label>
                <select
                  className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  id="category"
                  name="category"
                >
                  <option value="General">General</option>
                  <option value="Order">Order</option>
                  <option value="Pickup">Pickup</option>
                  <option value="Shipping">Shipping</option>
                  <option value="Delivery">Delivery</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <div className="flex flex-wrap mb-6 -mx-3">
              <div className="w-full px-3">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  for="grid-password"
                >
                  E-mail
                </label>
                <input
                  className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  type="email"
                  name="email"
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-6 -mx-3">
              <div className="w-full px-3">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  for="grid-password"
                >
                  Message
                </label>
                <textarea
                  className="block w-full h-48 px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none resize-none no-resize focus:outline-none focus:bg-white focus:border-gray-500"
                  id="message"
                  name="Message"
                ></textarea>
              </div>
            </div>
            <div className="md:flex md:items-center">
              <div className="md:w-1/3">
                <button
                  className="btn btn-block"
                  variant="dark"
                  style={{ backgroundColor: "black", color: "whitesmoke" }}
                  type="button submit"
                >
                  Send
                </button>
              </div>
              <div className="md:w-2/3"></div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Support;
