import { motion } from "framer-motion";
import { useState } from "react";
import {
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";

import { BiSolidCake } from "react-icons/bi";
import { MdOutlineSpeed, MdDeliveryDining } from "react-icons/md";

const CollapseCardFeatures = () => {
  const [position, setPosition] = useState(0);

  const shiftLeft = () => {
    if (position > 0) {
      setPosition((pv) => pv - 1);
    }
  };

  const shiftRight = () => {
    if (position < features.length - 1) {
      setPosition((pv) => pv + 1);
    }
  };

  return (
    <section className="overflow-hidden px-4 py-12">
      <div className="section-styling">
        <div className="mb-8 flex justify-between gap-4">
          <h2 className="text-4xl font-bold leading-[1.2] md:text-5xl">
            Our <span className="text-emerald-400">Services.</span>
          </h2>
          <div className="flex gap-2">
            <button
              className="h-fit bg-black p-4 text-2xl text-white transition-colors hover:bg-neutral-700"
              onClick={shiftLeft}
            >
              <FiChevronLeft />
            </button>
            <button
              className="h-fit bg-black p-4 text-2xl text-white transition-colors hover:bg-neutral-700"
              onClick={shiftRight}
            >
              <FiChevronRight />
            </button>
          </div>
        </div>
        <div className="flex gap-4">
          {features.map((feat, index) => (
            <Feature {...feat} key={index} position={position} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

const Feature = ({ position, index, title, description, Icon }) => {
  const translateAmt =
    position >= index ? index * 100 : index * 100 - 100 * (index - position);

  return (
    <motion.div
      animate={{ x: `${-translateAmt}%` }}
      transition={{
        ease: "easeInOut",
        duration: 0.35,
      }}
      className={`relative flex min-h-[250px] w-10/12 max-w-lg shrink-0 flex-col justify-between overflow-hidden p-8 shadow-lg md:w-3/5 ${index % 2 ? "bg-emerald-500 text-white" : " bg-white"
        }`}
    >
      <Icon className="absolute right-2 top-2 text-7xl opacity-50 mr-4 mt-2" />
      <h3 className={`mb-8 text-3xl font-bold ${index % 2 ? "text-white" : "text-black"}`}>{title}</h3>
      <p className="font-medium">{description}</p>
    </motion.div>
  );
};

export default CollapseCardFeatures;

const features = [
  {
    title: "Quality Treats",
    Icon: BiSolidCake,
    description:
      "I'm dedicated to crafting high-quality treats using only the finest ingredients. Every one of my creations is infused with love and made from scratch, and it's this personal touch that makes them truly unforgettable.",
  },
  {
    title: "Fast Service",
    Icon: MdOutlineSpeed,
    description:
      "I understand your time is valuable, and I aim to fulfill your order promptly. Please anticipate a maximum wait time of 24 hours for me to get in touch with you. Rest assured, once we connect, your order will be prepared fresh and tailored to your preferences. Your satisfaction is my priority.",
  },
  {
    title: "Getting Your Order",
    Icon: MdDeliveryDining,
    description:
      "Kindly note that all orders must be collected from my location. However, if you are unable to pick up your order in person, we offer a convenient delivery option for an additional fee. Please feel free to reach out to me for further details and to explore delivery options.",
  },

];