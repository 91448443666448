/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import Meta from "../components/Meta";
import { listProducts } from "../actions/productActions";
import Hero from "../components/Hero";
import TopProducts from "../components/TopProducts";
import BoutMe from "../components/BoutMe";
import CollapseCardFeatures from "../components/CollapseCardFeatures";


const HomeScreen = ({ match, history, location }) => {
  const keyword = match.params.keyword;

  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber));
  }, [dispatch, keyword, pageNumber]);

  return (
    <>
      <Meta />
      <Hero />
      <main className="py-3">
        {!keyword && pageNumber === 1 ? (
          <>
            <BoutMe />
            <CollapseCardFeatures />
            <TopProducts />
          </>
        ) : (
          <Link
            to="/"
            className="w-full text-white bg-black btn hover:bg-gray-700"
          >
            Go Back
          </Link>
        )}
        {/* {!keyword && pageNumber === 1 ? (
          <>

          </>
        ) : (
          <></>
        )} */}
      </main>
    </>
  );
};

export default HomeScreen;
