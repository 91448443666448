import axios from "axios";
import { CART_CLEAR_ITEMS } from "../constants/cartConstants";
import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_REQUEST,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_FAIL,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_SUCCESS,
  ORDER_DELIVER_REQUEST,
  ORDER_STATUS_FAIL,
  ORDER_STATUS_REQUEST,
  ORDER_DELETE_FAIL,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_REQUEST,
  ORDER_STATUS_SUCCESS,
  ORDER_PACKED_REQUEST,
  ORDER_PACKED_SUCCESS,
  ORDER_PACKED_FAIL,
  ORDER_DISPATCHED_REQUEST,
  ORDER_DISPATCHED_SUCCESS,
  ORDER_DISPATCHED_FAIL,
  ORDER_CANCEL_REQUEST,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAIL,
  // ORDER_SHIPMENTPAYMENT_REQUEST,
  // ORDER_SHIPMENTPAYMENT_SUCCESS,
  // ORDER_SHIPMENTPAYMENT_FAIL,
} from "../constants/orderConstants";
import { logout } from "./userActions";

export const createOrder = (order) => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: ORDER_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(`/api/orders`, order, config);

      console.log("Created Order: ", data)

      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: CART_CLEAR_ITEMS,
        payload: data,
      });
      localStorage.removeItem("cartItems");
    } else if (process.env.NODE_ENV === "production") {
      dispatch({
        type: ORDER_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `https://backend.annasweettreats.com/api/orders`,
        order,
        config
      );

      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: CART_CLEAR_ITEMS,

        payload: data,
      });
      localStorage.removeItem("cartItems");
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: message,
    });
  }
};

export const deleteOrder = (id) => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: ORDER_DELETE_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(`/api/orders/${id}`, config);

      dispatch({ type: ORDER_DELETE_SUCCESS });
    } else if (process.env.NODE_ENV === "production") {
      dispatch({
        type: ORDER_DELETE_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(
        `https://backend.annasweettreats.com/api/orders/${id}`,
        config
      );

      dispatch({ type: ORDER_DELETE_SUCCESS });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: ORDER_DETAILS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/orders/${id}`, config);

      dispatch({
        type: ORDER_DETAILS_SUCCESS,
        payload: data,
      });
    } else if (process.env.NODE_ENV === "production") {
      dispatch({
        type: ORDER_DETAILS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `https://backend.annasweettreats.com/api/orders/${id}`,
        config
      );

      dispatch({
        type: ORDER_DETAILS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: message,
    });
  }
};

// ORDER PACKED ACTION
export const orderPacked = (order) => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: ORDER_PACKED_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/orders/${order._id}/packed`,
        {},
        config
      );

      dispatch({
        type: ORDER_PACKED_SUCCESS,
        payload: data,
      });
    }

    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: ORDER_PACKED_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `https://backend.annasweettreats.com/api/orders/${order._id}/packed`,
        {},
        config
      );

      dispatch({
        type: ORDER_PACKED_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: ORDER_PACKED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ORDER CANCEL ACTION
export const orderCancelled = (order) => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: ORDER_CANCEL_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/orders/${order._id}/cancelled`,
        {},
        config
      );

      dispatch({
        type: ORDER_CANCEL_SUCCESS,
        payload: data,
      });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: ORDER_CANCEL_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `https://backend.annasweettreats.com/api/orders/${order._id}/cancelled`,
        {},
        config
      );

      dispatch({
        type: ORDER_CANCEL_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: ORDER_CANCEL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ORDER DISPATCHED ACTION
export const orderDispatched = (order) => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: ORDER_DISPATCHED_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/orders/${order._id}/dispatched`,
        {},
        config
      );

      dispatch({
        type: ORDER_DISPATCHED_SUCCESS,
        payload: data,
      });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: ORDER_DISPATCHED_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `https://backend.annasweettreats.com/api/orders/${order._id}/dispatched`,
        {},
        config
      );

      dispatch({
        type: ORDER_DISPATCHED_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: ORDER_DISPATCHED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deliverOrder = (order) => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: ORDER_DELIVER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/orders/${order._id}/deliver`,
        {},
        config
      );

      dispatch({
        type: ORDER_DELIVER_SUCCESS,
        payload: data,
      });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: ORDER_DELIVER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `https://backend.annasweettreats.com/api/orders/${order._id}/deliver`,
        {},
        config
      );

      dispatch({
        type: ORDER_DELIVER_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_DELIVER_FAIL,
      payload: message,
    });
  }
};

// export const orderShipmentPaymentLink = (order) => async (dispatch, getState) => {
//   try {
//     dispatch({
//       type: ORDER_SHIPMENTPAYMENT_REQUEST,
//     })

//     const {
//       userLogin: { userInfo },
//     } = getState()

//     const config = {
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     }

//     const { data } = await axios.put(
//       `/api/orders/${order._id}/shipmentpaymentlink`,
//       {},
//       config
//     )

//     dispatch({
//       type: ORDER_SHIPMENTPAYMENT_SUCCESS,
//       payload: data,
//     })
//   } catch (error) {
//     const message =
//       error.response && error.response.data.message
//         ? error.response.data.message
//         : error.message
//     if (message === 'Not authorized, token failed') {
//       dispatch(logout())
//     }
//     dispatch({
//       type: ORDER_SHIPMENTPAYMENT_FAIL,
//       payload: message,
//     })
//   }
// }

export const orderStatus = (order) => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: ORDER_STATUS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/orders/${order._id}/status`,
        {},
        config
      );

      dispatch({
        type: ORDER_STATUS_SUCCESS,
        payload: data,
      });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: ORDER_STATUS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `https://backend.annasweettreats.com/api/orders/${order._id}/status`,
        {},
        config
      );

      dispatch({
        type: ORDER_STATUS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_STATUS_FAIL,
      payload: message,
    });
  }
};

export const listMyOrders = () => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: ORDER_LIST_MY_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(`/api/orders/myorders`, config);

      dispatch({
        type: ORDER_LIST_MY_SUCCESS,
        payload: data,
      });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: ORDER_LIST_MY_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `https://backend.annasweettreats.com/api/orders/myorders`,
        config
      );

      dispatch({
        type: ORDER_LIST_MY_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_LIST_MY_FAIL,
      payload: message,
    });
  }
};

export const listOrders = () => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: ORDER_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(`/api/orders`, config);

      dispatch({
        type: ORDER_LIST_SUCCESS,
        payload: data,
      });
    }
    if (process.env.NODE_ENV === "production") {
      dispatch({
        type: ORDER_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `https://backend.annasweettreats.com/api/orders`,
        config
      );

      dispatch({
        type: ORDER_LIST_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_LIST_FAIL,
      payload: message,
    });
  }
};
