import React from 'react'
import Anaid from "../assets/anaid.jpg"

const BoutMe = () => {
    return (
        <div className='section-styling'>
            <div className='flex flex-col lg:flex-row justify-evenly'>

                <img src={Anaid} alt="Baker" className='rounded w-full mb-5 lg:w-[30%] ' />

                <div className='w-full lg:w-[60%] items-center'>

                    <h1 className='text-4xl font-bold text-emerald-600'>Hey, I'm Anna!</h1>
                    <p className='font-medium text-lg mt-3'>
                        Hello, I'm Anna! I'm a teenage baker, a creator of delightful sweets, and a positive influencer. In June 2020, I embarked on my own small venture, Anna Sweet Treats, to share my culinary creations with the world and indulge in my greatest passion. Baking and crafting various desserts not only allow me to blend my artistic and culinary talents but also bring forth incredible creations.
                        <br />
                        I am entirely self-taught, having acquired my knowledge by diligently watching online videos. I'm thrilled to have you join me on this sweet journey! To catch a glimpse behind the scenes of my business, please follow me on Instagram @anna.sweet.treats. 😊
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BoutMe