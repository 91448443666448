/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FiBatteryCharging, FiWifi } from "react-icons/fi";

import DisplayAnimation from "../assets/AnaidSlideShow.gif";

const Hero = () => {


  const FloatingPhone = () => {
    return (
      <div
        style={{
          transformStyle: "preserve-3d",
          transform: "rotateY(-30deg) rotateX(15deg)",
        }}
        className="rounded-[24px] bg-emerald-500"
      >
        <motion.div
          initial={{
            transform: "translateZ(8px) translateY(-2px)",
          }}
          animate={{
            transform: "translateZ(32px) translateY(-8px)",
          }}
          transition={{
            repeat: Infinity,
            repeatType: "mirror",
            duration: 2,
            ease: "easeInOut",
          }}
          className="relative h-96 w-56 rounded-[24px] border-2 border-b-4 border-r-4 border-white border-l-neutral-200 border-t-neutral-200 bg-neutral-900 p-1 pl-[3px] pt-[3px]"
        >
          <HeaderBar />
          <Screen />
        </motion.div>
      </div>
    );
  };

  const HeaderBar = () => {
    return (
      <>
        <div className="absolute left-[50%] top-2.5 z-10 h-2 w-16 -translate-x-[50%] rounded-md bg-neutral-900"></div>
        <div className="absolute right-3 top-2 z-10 flex gap-2">
          <FiWifi className="text-white drop-shadow-lg  " />
          <FiBatteryCharging className="text-white drop-shadow-lg" />
        </div>
      </>
    );
  };

  const Screen = () => {
    return (
      <div className="relative z-0 grid h-full w-full place-content-center overflow-hidden rounded-[20px] bg-white">
        {/* Example logo from logoispum */}
        <img src={DisplayAnimation} alt="logo" className="h-full w-full opacity-80" />

        <a href="sms: 3213169898">
          <button className="absolute bottom-4 left-4 right-4 z-10 rounded-lg border-[1px] bg-white py-2 text-sm font-medium text-violet-500 backdrop-blur hover:opacity-80">
            Text Me!
          </button>
        </a>

        {/* <div className="absolute -left-32 -top-32 h-64 w-64 rounded-full bg-violet-500" /> */}
        <div className="absolute -bottom-72 left-[50%] h-96 w-96 -translate-x-[50%] rounded-full bg-emerald-600" />
      </div>
    );
  };



  return (
    <>
      <div className="-mt-4">
        <div className="hero-alternative">
          <div className="hero-alternative-intro">

            <h1 className="font-bold text-emerald-600">Life is short
              make it sweet!</h1>
            <p className="mb-3">
              Get ready to Experience a delectable journey where heavenly
              flavors and irresistible aromas dance together, indulging your
              senses in a symphony of sweetness.
            </p>
            <div>
              <Link
                to="/products"
                className="btn bg-azalea-500 text-white w-1/4 hover:bg-azalea-400 rounded shop-button"
              >
                <i className="fas fa-shopping-bag"></i> Shop Now
              </Link>
              <Link
                to="/support"
                className="w-1/5 ml-2 rounded btn contact-button"
              >
                <i className="fas fa-envelope"></i> Contact Us
              </Link>
            </div>
          </div>
          {/* GIF */}
          <div className="products-gif">
            <section className="grid place-content-center p-6">
              <FloatingPhone />
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
