import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_ORDERNOTES,
} from "../constants/cartConstants";

export const addToCart = (id, qty, newPrice, option) => async (dispatch, getState) => {
  if (process.env.NODE_ENV === "development") {
    const { data } = await axios.get(
      `http://localhost:2350/api/products/${id}`
    );

    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        product: data._id,
        name: data.name,
        image: data.image,
        price: data.price,
        newPrice,
        option,
        countInStock: data.countInStock,
        qty,
        digitalLink: data.digitalLink,
      },
    });

    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  }
  if (process.env.NODE_ENV === "production") {
    const { data } = await axios.get(
      `https://backend.annasweettreats.com/api/products/${id}`
    );

    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        product: data._id,
        name: data.name,
        image: data.image,
        price: data.price,
        newPrice,
        option,
        countInStock: data.countInStock,
        qty,
        digitalLink: data.digitalLink,
      },
    });

    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  }
};

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const saveOrderNotes = (data) => (dispatch) => {
  console.log("Notes to be saved: ", data)
  dispatch({
    type: CART_SAVE_ORDERNOTES,
    payload: data,
  });
  localStorage.setItem("orderNotes", data);
};